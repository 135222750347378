import ScrollMagic from './vendors/ScrollMagic';

/**
 * Image apparition on scroll
 */
export const scrollImageAppear = function () {

    const controller = new ScrollMagic.Controller();

    let $images = jQuery('.image-appear');

    $images.each(function () {
        new ScrollMagic.Scene({
            triggerElement: this,
            reverse: false,
            triggerHook: 0.95
        })
            .setClassToggle(this, 'is-active')
            .addTo(controller);
    });

};

/**
 * Fade in up
 */
export const scrollFadeInUp = function () {
    const controller = new ScrollMagic.Controller();

    let $fadeInUp = jQuery('.fade-in-up');

    $fadeInUp.each(function () {
        new ScrollMagic.Scene({
            triggerElement: this,
            reverse: false,
            triggerHook: 0.95
        })
            .setClassToggle(this, 'is-active')
            .addTo(controller);
    });
};