import {$} from './helpers';
import Swiper from './vendors/swiper';

/**
 * Move navigations according to the active slide
 *
 * @param slider
 * @param $navigationContainer
 */
const moveNavigations = (slider, $navigationContainer) => {
  let $activeSlide = $(slider.slides[slider.activeIndex]),
      width = $activeSlide[0].getBoundingClientRect().width;

  $navigationContainer.css('left', width + 'px');
};

const itemsSliders = (
    selectorContainer = '.items-slider-container',
    slider = '.items-slider'
) => {
  if ($(selectorContainer).length) {
    $(selectorContainer).each(function () {
      let _this = this;

      let itemSlider = new Swiper($(_this).find(slider), {
        slidesPerView: 1,
        spaceBetween: 0,
        observer: true,
        observeParents: true,
        scrollbar: {
          el: _this.querySelector('.items-slider-scrollbar'),
          draggable: true
        },
        navigation: {
          prevEl: _this.querySelector('.swiper-navigation__left'),
          nextEl: _this.querySelector('.swiper-navigation__right')
        },
        breakpoints: {
          500: {
              slidesPerView: 2,
              spaceBetween: 15
          },
          650: {
              slidesPerView: 3,
              spaceBetween: 15
          },
          1350: {
              slidesPerView: 3,
              spaceBetween: 35,
          }
        }
      });
    });
  }
};

const textSliders = () => {
  let $selectorContainer = $('.text-slider');

  if ($selectorContainer.length) {
    $selectorContainer.each(function () {
      let _this = this;

      let textSlider = new Swiper($(_this).find('.default-carousel'), {
          slidesPerView: 1.2,
          spaceBetween: 10,
          loop: true,
          observer: true,
          observerParents: true,
          navigation: {
            prevEl: _this.querySelector('.text-slider__navigations__left'),
            nextEl: _this.querySelector('.text-slider__navigations__right')
          },
          breakpoints: {
            0: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            1024: {
                slidesPerView: 1.3,
                spaceBetween: 20,
            }
          },
          on: {
            resize: function () {
                moveNavigations(textSlider, $(_this).find('.text-slider__navigations'));
            },
            slideChange: function () {
                moveNavigations(textSlider, $(_this).find('.text-slider__navigations'));
            }
          }
      });

      moveNavigations(textSlider, $(_this).find('.text-slider__navigations'));
    });
  }
};

export const initSliders = () => {
  itemsSliders();
  textSliders();
};