import './vendors/doubleTapToGo';
import GumShoe from './vendors/gumShoe';
import {$, backToTop, textareaResize, lineBlock, fileUpload} from './helpers';
import {responsiveMenu, subMenu, stickyHeader, searchForm} from "./functions";
import {initCursors} from "./components/cursor";
import {initSliders} from "./sliders";
import {scrollFadeInUp, scrollImageAppear} from './scroll-animations';

const $body = $('body'),
      $redirectLinksBlock = $('.redirect-links--block'),
      $anchorBlock = $('.anchor-block');

let onReady,
    onLoad,
    onResize,
    onScroll,
    timer = null,
    lastScrollTop = 0,
    width = $(window).width();

/**
 * When document is ready
 */
onReady = function () {

    $body.removeClass('is-loading').addClass('is-loaded');

    // if ($body.hasClass('home') && $('#loader').length && document.referrer !== '') {
    //     $('#loader').remove();
    //     $body.css('overflow-y', 'auto');
    //     $('#wrapper').addClass('no-anim').css('opacity', 1);
    // }
    //
    // if ($body.hasClass('home') && $('#loader').length && document.referrer === '') {
    //     $('#loader').show();
    //     setTimeout(function () {
    //         $('#loader')
    //             .fadeOut(500, function () {
    //                 $(this).remove();
    //                 $body.css('overflow-y', 'auto');
    //                 $('#wrapper').css('opacity', 1);
    //             });
    //     }, 5000);
    // }

    // Remove VC tabs animations
    if (typeof jQuery.fn.vcAccordion !== "undefined") jQuery.fn.vcAccordion.Constructor.prototype.isAnimated = () => 0;

    if ($('.rellax').length) new Rellax('.rellax', { horizontalParallax: true, speed: 3 });

    stickyHeader();
    responsiveMenu.init();
    subMenu.init();
    initSliders();
    initCursors();
    backToTop();
    textareaResize();
    searchForm();
    scrollFadeInUp();
    scrollImageAppear();
    fileUpload();

    // Text widget
    let fontSizeFactor = 1.2,
        $allElements = $('.page-wrapper *:not(.reset--a)');

    $('.text-widget__up').on('click', function () {
        $allElements.each(function () {
           let fontSize = $(this).css('font-size');

           if (!!fontSize) {
               $(this).css({
                   'font-size': `${parseFloat(fontSize) * fontSizeFactor}px`,
                   'line-height': 1.2
               });
           }
        });
    });

    $('.text-widget__down').on('click', function () {
        $allElements.each(function () {
            let fontSize = $(this).css('font-size');

            if (!!fontSize) {
                $(this).css({
                    'font-size': `${parseFloat(fontSize) / fontSizeFactor}px`,
                    'line-height': 1.2
                });
            }
        });
    });

    // Anchor block
    if ($anchorBlock.length) {
        new GumShoe('.anchor-block__links a', {
            nested: true,
            reflow: true,
            offset: function () {
                let baseOffset = 20;

                if ($('body.admin-bar')) baseOffset += $('#wpadminbar').outerHeight();
                if ($('.sticky-header').is(':visible')) baseOffset += $('.sticky-header').outerHeight();

                return baseOffset;
            }
        });
    }

    // Redirect links block
    if ($redirectLinksBlock.length) {
        $redirectLinksBlock.find('a').doubleTapToGo();
        $redirectLinksBlock.find('a')
            .on('mouseenter', function () {
               $(this).find('ul').first().stop().slideDown();
            })
            .on('mouseleave', function () {
                $(this).find('ul').first().stop().slideUp();
            });
    }

    // Smooth scroll team page
    $('.team-section__links a').on('click', function (e) {
        e.preventDefault();
        let hash = this.hash,
            offset = 20;

        if ($('body').hasClass('admin-bar')) offset += 32;
        if ($('.sticky-header').is(':visible')) offset += $('.sticky-header').outerHeight();

        if (hash !== '') {
            $('html, body').stop().animate({
                scrollTop: $(hash).offset().top - offset
            }, 500, function () {
                history.pushState ? history.pushState(null, null, hash) : location.hash = hash;
            });
        }

        return false;
    });

    // Header menu have a menu item enabled
    let $headerMenu = $('.header__menu');

    if ($headerMenu.length) {
        if ($headerMenu.find(
            '.current-menu-item, ' +
            '.current-menu-ancestor, ' +
            '.current-category-ancestor, ' +
            '.current-post-ancestor, ' +
            '.current-page-ancestor'
        ).length) {
            $headerMenu.addClass('header__menu--is-active');
        }
    }

    // Popup Fermeture Cabinet
    function _hasCookies(cookieName){
        if(document.cookie.indexOf(cookieName+"=true") > -1){
            return true;
        } else if(document.cookie.indexOf(cookieName+"=false") > -1){
            return false;
        }
        return null;
    }
    function _setCookies(name, value, minutes) {
        var d = new Date();
        d.setTime(d.getTime() + (minutes * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }

    if (_hasCookies('PopupCabinetClosed') == null) {
      setTimeout(function () {
        $('.popup_closeinfos, .popup_closeinfos__overlay').fadeIn(400);
      }, 1000);
    }

    $(document).on('click', '.popup_closeinfos__close', function(event) {
      $('.popup_closeinfos, .popup_closeinfos__overlay').fadeOut('400');
      _setCookies("PopupCabinetClosed",true,60);
      event.preventDefault();
    });
    
};

/**
 * When window is loaded
 */
onLoad = function () {
    lineBlock();
};

/**
 * When window is resized
 */
onResize = function () {
    stickyHeader();
    subMenu.init();
    initCursors();
    lineBlock();

    /* Remove transitions on window resize */
    if ($(this).width() !== width) {
        width = $(this).width();

        if (timer) {
            clearTimeout(timer);
            timer = null;
        } else {
            $body.addClass('stop-transitions');
        }

        timer = setTimeout(() => {
            $body.removeClass('stop-transitions');
            timer = null;
        }, 100);
    }

    // Redirect blocks reset
    $redirectLinksBlock.find('ul').removeAttr('style');
};

/**
 * When window is scrolled
 */
onScroll = function () {
    let scrollTop = $(this).scrollTop();
    if (scrollTop > lastScrollTop) {
        // Down
        stickyHeader('down');
    } else {
        // Up
        stickyHeader('up');
    }
    lastScrollTop = scrollTop;
};

const App = {

    init: function () {

        $(document).on('ready', onReady);
        $(window).on('load', onLoad);
        $(window).on('resize', onResize);
        $(window).on('scroll', onScroll);

    }

};

App.init();