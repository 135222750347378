import {$} from './helpers';

const $body = $('body');

/**
 * Sticky header
 */
export const stickyHeader = function (direction = '') {
    let $clonedHeader = $('.header--cloned'),
        $header = $clonedHeader.length ? $('.header').eq(1) : $('.header').eq(0),
        $responsiveHeader = $('.responsive-header').eq(0),
        $clonedResponsiveHeader = $('.responsive-header--cloned'),
        scrollTop = $(window).scrollTop(),
        headerVisible = $header.is(':visible'),
        responsiveHeaderVisible = $responsiveHeader.is(':visible'),
        headerHeight = headerVisible ? $header.outerHeight() : 0,
        responsiveHeaderHeight = responsiveHeaderVisible ? $responsiveHeader.outerHeight() : 0;

    // Sticky desktop header
    if (headerVisible) {
        if (!$clonedHeader.length) {
            $header
                .clone(true)
                .insertAfter($header)
                .addClass('header--cloned');

            $body.addClass('stop-transitions__sticky-header');

            $header
                .removeClass('header--home')
                .addClass('sticky-header');

            setTimeout(function () {
                $body.removeClass('stop-transitions__sticky-header');
            }, 500);
        }

        if (scrollTop > headerHeight) {
            $body.addClass('sticky-header--is-active');
        } else {
            $body.removeClass('sticky-header--is-active');
        }
    } else {
        if ($clonedHeader.length) $clonedHeader.remove();
        $header.removeClass('sticky-header');
    }

    // Sticky responsive header
    if (responsiveHeaderVisible) {
        if (!$clonedResponsiveHeader.length) {
            $responsiveHeader
                .clone(true)
                .insertAfter($responsiveHeader)
                .addClass('responsive-header--cloned');

            $body.addClass('stop-transitions__sticky-header');

            $responsiveHeader.addClass('sticky-header');

            setTimeout(function () {
                $body.removeClass('stop-transitions__sticky-header');
            }, 500);
        }

        if ((scrollTop > responsiveHeaderHeight) && direction === 'up') {
            $body.addClass('sticky-header--is-active');
        } else {
            $body.removeClass('sticky-header--is-active');
        }
    } else {
        if ($clonedResponsiveHeader.length) $clonedResponsiveHeader.remove();
        $responsiveHeader.removeClass('sticky-header');
    }
};

/**
 * Manage sub menu
 */
export const subMenu = {

    menu: '.sticky-header, .header',

    /**
     * Make sub menu edge
     */
    edge: function () {
        $(this.menu).find('li.menu-item-has-children > .sub-menu').each(function () {
           let $this = $(this),
               $window = $(window);

           $this.parent().removeClass('sub-menu--edge');

           if ($this.offset().left + 100 > $window.width() + $window.scrollLeft() - $this.width()) {
               $this.parent().addClass('sub-menu--edge');
           }
        });
    },

    /**
     * Hover sub menu
     */
    hover: function () {
        let activeMenu = null;

        $(this.menu).find('li.menu-item-has-children')
            .on('mouseenter', function () {
                let $this = $(this);

                clearTimeout(activeMenu);
                $this.parent().find('li.menu-item-has-children.is-active').removeClass('is-active');
                $this.addClass('is-active');
            })
            .on('mouseleave', function () {
                let $this = $(this);

                activeMenu = setTimeout(function () {
                    $this.removeClass('is-active');
                }, 150);
            });
    },

    /**
     * Init sub menu
     */
    init: function () {
        this.edge();
        this.hover();
    }

};

/**
 * Manage responsive Menu
 */
export const responsiveMenu = {

    responsiveMenuClass: '.responsive-menu',
    responsiveMenuOverlayClass: '.responsive-menu-overlay',
    openClass: '.responsive-header__bottom__hamburger',
    bodyClass: 'responsive-menu--is-active',

    /**
     * Toggle the menu
     */
    menuToggle: function () {
        let $open = $(document).find(this.openClass),
            bodyClass = this.bodyClass;

        $open.on('click', function (e) {
           e.preventDefault();
           $body.addClass(bodyClass);
        });

        $(this.responsiveMenuOverlayClass).on('click', function () {
           $body.removeClass(bodyClass);
        });
    },

    /**
     * Toggle sub menu
     */
    subMenuToggle: function () {
        let $responsiveMenu = $(this.responsiveMenuClass);

        $responsiveMenu.find('li.menu-item-has-children > a').on('click', function (e) {
            let $this = $(this);
            e.preventDefault();
            $this.parent().toggleClass('is-active');
            $this.parent().find('.sub-menu').first().slideToggle();
        });

        $responsiveMenu.find('li.menu-item-has-children > a > span').on('click', function (e) {
            e.stopPropagation();
        });
    },

    /**
     * Init responsive menu functions
     */
    init: function () {
        this.menuToggle();
        this.subMenuToggle();
    }

};

/**
 * Toggle search form
 */
export const searchForm = (bodyClass = 'search--is-active') => {
    let $openers = $(document).find('.header__search, .sticky-header__search, .responsive-header__bottom__search'),
        $closers = $('.search-form__close'),
        $searchInput = $('.search-form__input');

    $openers.on('click', function (e) {
        e.preventDefault();
        $body.addClass(bodyClass);
        $searchInput.focus();
    });

    $closers.on('click', function (e) {
       e.preventDefault();
       $body.removeClass(bodyClass);
    });
};