/**
 * $ helper (instead of jQuery)
 */
export const $ = (el) => jQuery(el);

/**
 * Auto resize textarea (for a one line textarea)
 *
 * @param el
 */
const autosize = function(el) {
    setTimeout(function () {
        jQuery(el).css('height', 'auto');
        jQuery(el).css('height', parseInt(el.scrollHeight) + 'px');
    }, 0);
};

/**
 * Activate the resize of textarea on enter or escape key
 */
export const textareaResize = function () {
    jQuery('textarea').keydown(function (e) {
        let key = e.which || e.keyCode;
        if (key === 13 || key === 8) {
            autosize(this);
        }
    });
};

/**
 * File upload style + script to add image
 */
export const fileUpload = function (settings = {
    'file_upload_container': jQuery('.file-upload'),
    'file_upload_text': jQuery('.file-upload__text'),
    'file_upload_input': jQuery('.file-upload__input'),
    'file_upload_default_input': jQuery('.file-upload').find('input[type="file"]')
}) {
    jQuery(function () {
        let file_upload_container     = settings.file_upload_container,
            file_upload_text          = settings.file_upload_text,
            file_upload_input         = settings.file_upload_input,
            file_upload_default_input = settings.file_upload_default_input;

        // On click on the fake input, click on the file input
        file_upload_input.on('click', function () {
            file_upload_default_input.click();
        });

        // Text of the input
        file_upload_default_input.on('change', function () {
            let text = this.files[0].name; // Default text

            if (this.files.length > 1) {
                text = `${this.files.length} fichiers`;
            }

            file_upload_text.html(text);
        });

        // Reset file upload on mail sent
        jQuery('.wpcf7').each(function () {
            jQuery(this).on('wpcf7mailsent', function (event) {
                jQuery(this).find(file_upload_input).val('');
                jQuery(this).find(file_upload_text).html('Aucun fichier choisi');
            });
        });
    });
};

/**
 * Back to top
 */
export const backToTop = () => {
    let $backToTop = $('.back-to-top');

    $backToTop.on('click', function () {
        $('html, body').stop().animate({scrollTop: 0}, 500);
    });

    $(window).on('load scroll', function () {
       if ($backToTop.length) {
           let scrollTop = $(window).scrollTop();

           (scrollTop > 500) ? $backToTop.addClass('is-active') : $backToTop.removeClass('is-active');
       }
    });
};

/**
 * Manage line blocks (line moving on hover over a line)
 */
export const lineBlock = () => {
    let $lineBlock = jQuery('.line-block');

    if ($lineBlock.length) {
        $lineBlock.each(function () {
            let _this = jQuery(this),
                $element = _this.find('li'),
                $activeElement = _this.find('.is-active'),
                $line = _this.find('.line-block__line');

            // Activate the first element
            if ($activeElement.length) {
                $line.css({
                    width: $activeElement[0].getBoundingClientRect().width,
                    left: $activeElement.position().left
                });
            }

            // Move the line
            $element.on('mouseenter', function () {
                $line.css({
                    width: jQuery(this)[0].getBoundingClientRect().width,
                    left: jQuery(this).position().left
                });
            }).on('mouseleave', function () {
                if ($activeElement.length) {
                    $line.css({
                        width: $activeElement[0].getBoundingClientRect().width,
                        left: $activeElement.position().left
                    });
                }
            });
        });
    }
};